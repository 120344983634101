var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        visible: _vm.showDialog,
        title: "关联客户",
        width: "1100px",
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "wrapper" },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, inline: "" } },
            [
              _c(
                "el-form-item",
                [
                  _c("sale-area-select-tree", {
                    ref: "saleAreaSelectTree",
                    on: { "set-sale-select-id": _vm.setSaleSelectId },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: "请选择渠道" },
                      model: {
                        value: _vm.form.channel_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "channel_id", $$v)
                        },
                        expression: "form.channel_id",
                      },
                    },
                    _vm._l(_vm.channelList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: "请选择客户等级" },
                      model: {
                        value: _vm.form.class_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "class_id", $$v)
                        },
                        expression: "form.class_id",
                      },
                    },
                    _vm._l(_vm.clientLevelList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: "选择情况" },
                      model: {
                        value: _vm.statusids,
                        callback: function ($$v) {
                          _vm.statusids = $$v
                        },
                        expression: "statusids",
                      },
                    },
                    _vm._l(_vm.status, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("client-search", {
                    ref: "clientSelect",
                    attrs: { "popper-class": "selectDC" },
                    on: { "select-id": _vm.selectId },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              attrs: {
                stripe: "",
                "tooltip-effect": "dark",
                data: _vm.list,
                height: "320px",
                "row-key": _vm.handleReserve,
              },
              on: { "selection-change": _vm.handleSelectRow },
            },
            [
              _c(
                "el-table-column",
                { attrs: { align: "center", type: "index", width: "50" } },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            "popper-class": "custom-table-checkbox",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.checkList,
                                callback: function ($$v) {
                                  _vm.checkList = $$v
                                },
                                expression: "checkList",
                              },
                            },
                            _vm._l(_vm.columns, function (item, index) {
                              return _c("el-checkbox", {
                                key: index,
                                attrs: { label: item.label },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", type: "text" },
                              slot: "reference",
                            },
                            [
                              _c("vab-remix-icon", {
                                attrs: { icon: "settings-line" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  type: "selection",
                  "reserve-selection": "",
                  width: "50",
                },
              }),
              _vm._l(_vm.finalluColumns, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    label: item.label,
                    width: "",
                    prop: item.prop,
                    align: "center",
                  },
                })
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "bottom-units" },
            [
              _c(
                "el-form",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { model: _vm.bottomForm, inline: "" },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        { on: { click: _vm.handleBatchDeleteRow } },
                        [_vm._v("批量删除")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.form.pageNo,
                  layout: _vm.layout,
                  "page-size": _vm.form.pageSize,
                  total: _vm.totalCount,
                  background: "",
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersaveids } },
            [_vm._v("保 存")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
      _c("batch-add", {
        ref: "batchAdd",
        attrs: {
          "client-level-list": _vm.clientLevelList,
          "channel-list": _vm.channelList,
        },
        on: { refresh: _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }