<!--
 * @Author: your name
 * @Date: 2021-01-25 16:36:48
 * @LastEditTime: 2021-01-25 16:58:45
 * @LastEditors: Please set LastEditors
 * @Description: 用券明细
 * @FilePath: \sd-vue-admin\src\views\project\marketing\activity\coupon\components\VoucherDetails.vue
-->
<template>
  <div>
    <el-dialog :modal="false" :visible.sync="details" title="用券明细" center>
      <div>
        <h3>
          优惠券名称：
          <span>{{ title }}</span>
        </h3>

        <el-row style="margin-top: 20px">
          <el-table stripe :data="tableData">
            <el-table-column
              type="selection"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column
              v-for="(list, index) in colemd"
              :key="index"
              :label="list.label"
              :align="list.align"
              :prop="list.prop"
              width=""
            ></el-table-column>
            <!-- <el-table-column align="center" label="操作" width="220px">
          <template #default="{ row }">
            <el-button type="text" @click="handlermodify(row)">修改</el-button>
            <el-button type="text" @click="handlerlink(row)">
              领券明细
            </el-button>
            <el-button type="text" @click="handlerVoucher(row)">
              用券明细
            </el-button>
            <el-popconfirm
              title="您真的要终止这个活动吗？"
              @confirm="handlerstorp(row)"
            >
              <el-button
                v-show="row.status != 0"
                slot="reference"
                style="margin-left: 10px"
                type="text"
              >
                终止
              </el-button>
            </el-popconfirm>

            <el-popconfirm
              title="您真的要删除这个活动吗？"
              @confirm="handlerdelete(row)"
            >
              <el-button slot="reference" style="margin-left: 10px" type="text">
                删除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column> -->
          </el-table>
        </el-row>
        <el-row type="flex" class="row-bg" justify="end">
          <el-pagination
            background
            :current-page="form.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="details = !details">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    data() {
      return {
        details: false,
        title: '',
        form: {
          id: '',
          pageNo: 1,
          pageSize: 10,
          // sort:排序字段，默认id
          // order:排序规则，默认desc
        },
        url: {
          list: '/shopAdmin/coupon/use-detail',
        },
        tableData: [],
        total: 0,
        colemd: [
          {
            label: '订单号',
            align: 'center',
            prop: 'bill_code',
            width: '',
          },
          {
            label: '客户名称',
            align: 'center',
            prop: 'cust_name',
            width: '',
          },
          {
            label: '用券数量',
            align: 'center',
            prop: 'coupon_amount',
            width: '',
          },
          {
            label: '优惠金额',
            align: 'center',
            prop: 'coupon_amount',
            width: '',
          },
          {
            label: '订单金额',
            align: 'center',
            prop: 'total_amount',
            width: '',
          },
          {
            label: '收款',
            align: 'center',
            prop: 'pay_amount',
            width: '',
          },
          {
            label: '欠款',
            align: 'center',
            prop: 'left_amount',
            width: '',
          },
          {
            label: '下单时间',
            align: 'center',
            prop: 'create_at',
            width: '',
          },
        ],
      }
    },
    methods: {
      handlerInquire(row) {
        console.log(row)
        this.form.id = row.id
        this.form.pageNo = 1
        this.handlerlist()
      },
      handlerlist() {
        postAction(this.url.list, this.form)
          .then((res) => {
            console.log(res)
            this.title = res.data.coupon_name
            this.tableData = res.data.list
            this.total = res.totalCount
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerlist()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
    },
  }
</script>

<style></style>
