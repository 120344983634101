var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            visible: _vm.showcoupon,
            title: "发行新优惠券",
            center: "",
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showcoupon = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        "label-width": "120px",
                        rules: _vm.rules,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "优惠券名称：", prop: "name" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: { placeholder: "请输入优惠券名称" },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "优惠券面值：", prop: "amount" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: {
                              placeholder: "请输入优惠券面值",
                              onkeyup: "value=value.replace(/[^\\d.]/g,'') ",
                            },
                            model: {
                              value: _vm.form.amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "amount", $$v)
                              },
                              expression: "form.amount",
                            },
                          }),
                          _vm._v(" 元 "),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "使用门槛：", prop: "enough" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.Usethreshold,
                                callback: function ($$v) {
                                  _vm.Usethreshold = $$v
                                },
                                expression: "Usethreshold",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("无门槛"),
                              ]),
                              _c(
                                "el-radio",
                                { attrs: { label: 1 } },
                                [
                                  _vm._v(" 满 "),
                                  _c("el-input", {
                                    staticStyle: { width: "120px" },
                                    attrs: {
                                      disabled: _vm.Usethreshold == 0,
                                      placeholder: "",
                                      onkeyup:
                                        "value=value.replace(/[^\\d.]/g,'') ",
                                    },
                                    model: {
                                      value: _vm.form.enough,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "enough", $$v)
                                      },
                                      expression: "form.enough",
                                    },
                                  }),
                                  _vm._v(" 元 "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "发行数量：", prop: "total" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "120px" },
                            attrs: {
                              placeholder: "请输入发行数量",
                              onkeyup: "value=value.replace(/[^\\d.]/g,'') ",
                            },
                            model: {
                              value: _vm.form.total,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "total", $$v)
                              },
                              expression: "form.total",
                            },
                          }),
                          _vm._v(" 张 "),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "有效时间：", prop: "type" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "type", $$v)
                                },
                                expression: "form.type",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { attrs: { label: "1" } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "datetimerange",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                      "default-time": ["12:00:00"],
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      format: "yyyy-MM-dd HH:mm:ss",
                                    },
                                    on: { change: _vm.handlerpicker },
                                    model: {
                                      value: _vm.form.Time,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "Time", $$v)
                                      },
                                      expression: "form.Time",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-radio",
                                {
                                  staticStyle: { padding: "20px 0px" },
                                  attrs: { label: "0" },
                                },
                                [
                                  _vm._v(" 领券后 "),
                                  _c("el-input", {
                                    staticStyle: { width: "120px" },
                                    attrs: {
                                      disabled: _vm.form.type == 1,
                                      placeholder: "请输入天数",
                                    },
                                    model: {
                                      value: _vm.form.days,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "days", $$v)
                                      },
                                      expression: "form.days",
                                    },
                                  }),
                                  _vm._v(
                                    " 天过期，即优惠券从领取当天算起，超过使用天数后自动失效 "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "可领客户：",
                            prop: "limit_cust_ids",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.Receivable,
                                callback: function ($$v) {
                                  _vm.Receivable = $$v
                                },
                                expression: "Receivable",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("全部客户"),
                              ]),
                              _c(
                                "el-radio",
                                { attrs: { label: 1 } },
                                [
                                  _vm._v(" 指定客户 "),
                                  _c(
                                    "span",
                                    { staticStyle: { margin: "0px 15px" } },
                                    [_vm._v(_vm._s(_vm.user_data) + "人")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.Receivable != 0,
                                          expression: "Receivable != 0",
                                        },
                                      ],
                                      attrs: { type: "primary" },
                                      on: { click: _vm.handleSet },
                                    },
                                    [_vm._v(" 设置 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "每人限领：", prop: "getmax" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.Limitedcollar,
                                callback: function ($$v) {
                                  _vm.Limitedcollar = $$v
                                },
                                expression: "Limitedcollar",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("不限次数"),
                              ]),
                              _c(
                                "el-radio",
                                { attrs: { label: 1 } },
                                [
                                  _vm._v(" 限 "),
                                  _c("el-input", {
                                    staticStyle: { width: "80px" },
                                    attrs: {
                                      disabled: _vm.Limitedcollar == 0,
                                      placeholder: "请输入次数",
                                    },
                                    model: {
                                      value: _vm.form.getmax,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "getmax", $$v)
                                      },
                                      expression: "form.getmax",
                                    },
                                  }),
                                  _vm._v(" 次 "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "使用范围：", prop: "limit_goods" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.range,
                                callback: function ($$v) {
                                  _vm.range = $$v
                                },
                                expression: "range",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("全部商品"),
                              ]),
                              _c(
                                "el-radio",
                                { attrs: { label: 1 } },
                                [
                                  _vm._v(" 指定商品可用 "),
                                  _c(
                                    "span",
                                    { staticStyle: { margin: "0px 15px" } },
                                    [_vm._v(_vm._s(_vm.goods_data) + "个")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.range != "0",
                                          expression: "range != '0'",
                                        },
                                      ],
                                      attrs: { type: "primary" },
                                      on: { click: _vm.handlerAddgoods },
                                    },
                                    [_vm._v(" 设 置 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "使用说明：", prop: "remark" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "620px" },
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入内容",
                              rows: 4,
                              maxlength: "300",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("link-client", {
                ref: "linkClient",
                on: { setids: _vm.setids },
              }),
              _c("add-goods", {
                ref: "goods",
                on: { handlergitids: _vm.handlergitids },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showcoupon = !_vm.showcoupon
                    },
                  },
                },
                [_vm._v(" 取 消 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }