var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            visible: _vm.details,
            title: "领券明细",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.details = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("h3", [
                _vm._v(" 优惠券名称： "),
                _c("span", [_vm._v(_vm._s(_vm.title))]),
              ]),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-table",
                    { attrs: { stripe: "", data: _vm.tableData } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "55",
                          align: "center",
                        },
                      }),
                      _vm._l(_vm.colemd, function (list, index) {
                        return _c("el-table-column", {
                          key: index,
                          attrs: {
                            label: list.label,
                            align: list.align,
                            prop: list.prop,
                            width: "",
                          },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticClass: "row-bg",
                  attrs: { type: "flex", justify: "end" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.form.pageNo,
                      "page-sizes": [10, 20, 50, 100],
                      "page-size": _vm.form.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.details = !_vm.details
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }