<!--
 * @Author: your name
 * @Date: 2021-01-19 15:42:13
 * @LastEditTime: 2021-03-29 15:44:28
 * @LastEditors: Please set LastEditors
 * @Description: 优惠券
 * @FilePath: \sd-vue-admin\src\views\project\marketing\activity\coupon\index.vue
-->
<template>
  <div style="padding: 20px">
    <el-row :gutter="20">
      <el-col :span="17">
        <el-button type="primary" @click="handlerInquire">
          查询已发行的优惠券
        </el-button>
      </el-col>
      <el-col :span="3">
        <el-button @click="handlerAdd">发行新优惠券</el-button>
      </el-col>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-table stripe :data="tableData">
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        >
          <template v-if="list.label == '状态'" #default="{ row }">
            <span v-show="row.status == 1">开启</span>
            <span v-show="row.status == 0">终止</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="220px">
          <template #default="{ row }">
            <el-button type="text" @click="handlermodify(row)">修改</el-button>
            <el-button type="text" @click="handlerlink(row)">
              领券明细
            </el-button>
            <el-button type="text" @click="handlerVoucher(row)">
              用券明细
            </el-button>
            <el-popconfirm
              title="您真的要终止这个活动吗？"
              @confirm="handlerstorp(row)"
            >
              <el-button
                v-show="row.status != 0"
                slot="reference"
                style="margin-left: 10px"
                type="text"
              >
                终止
              </el-button>
            </el-popconfirm>

            <!-- <el-popconfirm
              title="您真的要删除这个活动吗？"
              @confirm="handlerdelete(row)"
            >
              <el-button slot="reference" style="margin-left: 10px" type="text">
                删除
              </el-button>
            </el-popconfirm> -->
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        background
        :current-page="form.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
    <addcoupon ref="coupon" @getlist="handlergetlist"></addcoupon>
    <coupon-details ref="details"></coupon-details>
    <voucher-details ref="voucher"></voucher-details>
  </div>
</template>

<script>
  import VoucherDetails from './components/VoucherDetails.vue'
  import CouponDetails from './components/CouponDetails.vue'
  import Addcoupon from './components/Addcoupon.vue'
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      Addcoupon,
      CouponDetails,
      VoucherDetails,
    },
    data() {
      return {
        url: {
          list: '/shopAdmin/coupon/list',
          storp: '/shopAdmin/coupon/stop',
        },
        tableData: [],
        total: 0,
        form: {
          pageSize: 10,
          pageNo: 1,
        },
        colemd: [
          {
            label: '优惠券名称',
            align: 'center',
            prop: 'name',
            width: '',
          },
          {
            label: '优惠券面值',
            align: 'center',
            prop: 'amount',
            width: '',
          },
          {
            label: '发行数量',
            align: 'center',
            prop: 'total',
            width: '',
          },
          {
            label: '获取数量',
            align: 'center',
            prop: 'get_num',
            width: '',
          },
          {
            label: '使用数量',
            align: 'center',
            prop: 'use_num',
            width: '',
          },
          {
            label: '发行时间',
            align: 'center',
            prop: 'create_at',
            width: '',
          },
          {
            label: '有效期截止时间',
            align: 'center',
            prop: 'date_limit',
            width: '',
          },
          {
            label: '优惠券状态',
            align: 'center',
            prop: 'staus_text',
            width: '',
          },
        ],
      }
    },
    mounted() {
      this.handlerInquire()
    },
    methods: {
      handlermodify(row) {
        this.$refs.coupon.handlerData(row)
        this.$refs.coupon.showcoupon = true
      },
      handlergetlist() {
        this.handlerlist()
      },
      handlerstorp(row) {
        postAction(this.url.storp, { id: row.id })
          .then((res) => {
            console.log(res)
            if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: 'success',
              })
              this.handlerlist()
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerVoucher(row) {
        this.$refs.voucher.handlerInquire(row)
        this.$refs.voucher.details = true
      },
      handlerlink(row) {
        this.$refs.details.handlerInquire(row)
        this.$refs.details.details = true
      },
      handlerAdd() {
        this.$refs.coupon.showcoupon = true
      },
      handlerInquire() {
        this.form.pageNo = 1
        this.handlerlist()
      },
      handlerlist() {
        postAction(this.url.list, this.form)
          .then((res) => {
            console.log(res)
            this.tableData = res.data
            this.total = res.totalCount
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerlist()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
    },
  }
</script>

<style></style>
