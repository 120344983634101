<!--
 * @Author: your name
 * @Date: 2021-01-26 10:55:24
 * @LastEditTime: 2021-01-26 11:32:12
 * @LastEditors: Please set LastEditors
 * @Description: 添加限定商品
 * @FilePath: \sd-vue-admin\src\views\project\marketing\activity\coupon\components\AddGoods.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :visible.sync="showgoods"
      title="添加限定商品"
      center
    >
      <div>
        <el-table stripe :data="tableData" height="300px">
          <el-table-column
            v-for="(list, index) in colemd"
            :key="index"
            :label="list.label"
            :align="list.align"
            :prop="list.prop"
            width=""
          >
            <template
              v-if="list.label == '商品名称'"
              #default="{ $index, row }"
            >
              <goods-search
                :f-key="row.goods_name"
                @add-rows="addRows"
                @select-goods-all="selectGoods($event, row, $index)"
              ></goods-search>
            </template>
            <!--            <template v-else-if="list.label == '单位'" #default="{ row }">-->
            <!--              <el-select-->
            <!--                v-model="row.unit_id"-->
            <!--                placeholder="选择单位"-->
            <!--                style="width: 70px"-->
            <!--                @change="unitChange($event, row)"-->
            <!--              >-->
            <!--                <el-option-->
            <!--                  v-for="item in row.arr_unit"-->
            <!--                  :key="item.id"-->
            <!--                  :label="item.unit_name"-->
            <!--                  :value="item.id"-->
            <!--                ></el-option>-->
            <!--              </el-select>-->
            <!--            </template>-->
            <!--            <template v-else-if="list.label == '活动价'" #default="{ row }">-->
            <!--              <el-input-->
            <!--                v-model="row.price"-->
            <!--                style="width: 80px"-->
            <!--                onkeyup="value=value.replace(/[^\d.]/g,'') "-->
            <!--              />-->
            <!--            </template>-->
            <!--            <template v-else #default="{ row }">-->
            <!--              {{ row[list.prop] }}-->
            <!--            </template>-->
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            fixed="right"
            width="120"
          >
            <template #default="{ row, $index }">
              <el-button type="text" @click="handlerAdd(row, $index)">
                添加
              </el-button>
              <el-button type="text" @click="handlerDelet(row, $index)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handlersave">保 存</el-button>
        <el-button @click="showgoods = !showgoods">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  export default {
    components: {
      GoodsSearch,
    },
    data() {
      return {
        showgoods: false,
        tableData: [
          {
            arr_unit: [],
            goods_id: '',
            goods_name: '',
            goods_specs: '',
            unit_id: '',
            unit_name: '',
            price: '',
            sell_price: '',
          },
        ],
        colemd: [
          {
            order: 1,
            label: '商品名称',
            align: 'center',
            prop: 'goods_name',
            width: '',
          },
          {
            order: 3,
            label: '规格',
            align: 'center',
            prop: 'goods_specs',
            width: '',
          },
          // {
          //   order: 2,
          //   label: '单位',
          //   align: 'center',
          //   prop: 'unit_id',
          //   width: '',
          // },
          // {
          //   order: 4,
          //   label: '销售价',
          //   align: 'center',
          //   prop: 'sell_price',
          //   width: '',
          // },
          // {
          //   order: 5,
          //   label: '活动价',
          //   align: 'center',
          //   prop: 'price',
          //   width: '',
          // },
        ],
      }
    },
    watch: {
      showgoods(val) {
        if (val == false) {
          this.tableData = [
            {
              arr_unit: [],
              goods_id: '',
              goods_name: '',
              goods_specs: '',
              unit_id: '',
              unit_name: '',
              price: '',
              sell_price: '',
            },
          ]
        }
      },
    },
    methods: {
      handlerinfolist(val) {
        let arr = val.split(',')
        postAction('baseAdmin/goods/window-view-do', { ids: arr, pageSize: -1 })
          .then((res) => {
            console.log(res)
            if (res.data.length > 0) {
              var arrdata = []
              res.data.forEach((list) => {
                let shagn = {
                  arr_unit: list.arr_unit,
                  goods_id: list.goods_id,
                  goods_name: list.goods_name,
                  goods_specs: list.specs,
                  unit_id: list.unit_id,
                  unit_name: list.unit_name,
                  price: list.goods_price,
                  sell_price: list.goods_price,
                }
                arrdata.push(shagn)
              })
              this.tableData = arrdata
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerAdd(row, index) {
        this.tableData.splice(index, 0, JSON.parse(JSON.stringify(row)))
      },
      handlerDelet(row, index) {
        if (this.tableData.length <= 1) {
          this.$message({
            type: 'warning',
            message: '不能再删除了',
          })
        } else {
          this.tableData.splice(index, 1)
        }
      },
      handlersave() {
        var a = this.tableData.filter((item) => {
          return item.goods_name != ''
        })
        var ids = new Array()
        var s = new Array()
        a.forEach((list) => {
          s.push(list.goods_id)
        })
        ids = s
        this.$emit('handlergitids', ids.join(','))
        this.showgoods = false
      },
      selectGoods(event, row, index) {
        console.log(event, row, '1')
        var data = {
          arr_unit: event.arr_unit,
          goods_id: event.goods_id,
          goods_name: event.goods_name,
          goods_specs: event.specs,
          unit_id: event.unit_id,
          unit_name: event.unit_name,
          price: event.goods_price,
          sell_price: event.goods_price,
        }
        // Object.assign(row, event)
        Object.assign(row, data)
      },
      addRows(val) {
        console.log('添加rows', val)
        console.log(val)
        if (val.length !== 0) {
          val.forEach((item) => {
            var a = {
              arr_unit: item.arr_unit,
              goods_id: item.goods_id,
              goods_name: item.goods_name,
              goods_specs: item.specs,
              unit_id: item.unit_id,
              unit_name: item.unit_name,
              price: item.goods_price,
              sell_price: item.goods_price,
            }
            this.tableData.splice(this.tableData.length - 1, 0, a)
          })
          this.$message.success('添加成功')
        }
      },
      // 表格内单位切换
      unitChange(e, row) {
        console.log(e, row)
        let price = row.arr_unit.filter((item) => item.id == e)[0].sell_price
        let name = row.arr_unit.filter((item) => item.id == e)[0].unit_name
        row.sell_price = price
        row.price = price
        row.unit_name = name
      },
      setUserId(val) {
        console.log(val, val.split(','))
        this.form.limit_users = val
        this.user_data = val.split(',').length
        // this.data.limit_count = this.data.limit_users.split(',').length
      },
    },
  }
</script>

<style></style>
