<!--
 * @Author: 候怀烨
 * @Date: 2021-01-25 11:42:12
 * @LastEditTime: 2021-03-29 15:38:57
 * @LastEditors: Please set LastEditors
 * @Description: 发行新优惠券
 * @FilePath: \sd-vue-admin\src\views\project\marketing\activity\coupon\components\Addcoupon.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :visible.sync="showcoupon"
      title="发行新优惠券"
      center
      width="60%"
    >
      <div>
        <el-row>
          <el-form ref="form" :model="form" label-width="120px" :rules="rules">
            <el-form-item label="优惠券名称：" prop="name">
              <el-input
                v-model="form.name"
                placeholder="请输入优惠券名称"
                style="width: 220px"
              />
            </el-form-item>
            <el-form-item label="优惠券面值：" prop="amount">
              <el-input
                v-model="form.amount"
                placeholder="请输入优惠券面值"
                style="width: 220px"
                onkeyup="value=value.replace(/[^\d.]/g,'') "
              />
              元
            </el-form-item>
            <el-form-item label="使用门槛：" prop="enough">
              <el-radio-group v-model="Usethreshold">
                <el-radio :label="0">无门槛</el-radio>
                <el-radio :label="1">
                  满
                  <el-input
                    v-model="form.enough"
                    :disabled="Usethreshold == 0"
                    placeholder=""
                    style="width: 120px"
                    onkeyup="value=value.replace(/[^\d.]/g,'') "
                  />
                  元
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="发行数量：" prop="total">
              <el-input
                v-model="form.total"
                placeholder="请输入发行数量"
                style="width: 120px"
                onkeyup="value=value.replace(/[^\d.]/g,'') "
              />
              张
            </el-form-item>
            <el-form-item label="有效时间：" prop="type">
              <el-radio-group v-model="form.type">
                <el-radio label="1">
                  <el-date-picker
                    v-model="form.Time"
                    type="datetimerange"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :default-time="['12:00:00']"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd HH:mm:ss"
                    @change="handlerpicker"
                  ></el-date-picker>
                </el-radio>

                <el-radio label="0" style="padding: 20px 0px">
                  领券后
                  <el-input
                    v-model="form.days"
                    :disabled="form.type == 1"
                    placeholder="请输入天数"
                    style="width: 120px"
                  />
                  天过期，即优惠券从领取当天算起，超过使用天数后自动失效
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="可领客户：" prop="limit_cust_ids">
              <el-radio-group v-model="Receivable">
                <el-radio :label="0">全部客户</el-radio>
                <el-radio :label="1">
                  指定客户
                  <span style="margin: 0px 15px">{{ user_data }}人</span>
                  <el-button
                    v-show="Receivable != 0"
                    type="primary"
                    @click="handleSet"
                  >
                    设置
                  </el-button>
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="每人限领：" prop="getmax">
              <el-radio-group v-model="Limitedcollar">
                <el-radio :label="0">不限次数</el-radio>
                <el-radio :label="1">
                  限
                  <el-input
                    v-model="form.getmax"
                    :disabled="Limitedcollar == 0"
                    placeholder="请输入次数"
                    style="width: 80px"
                  />
                  次
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="使用范围：" prop="limit_goods">
              <el-radio-group v-model="range">
                <el-radio :label="0">全部商品</el-radio>
                <el-radio :label="1">
                  指定商品可用
                  <span style="margin: 0px 15px">{{ goods_data }}个</span>
                  <el-button
                    v-show="range != '0'"
                    type="primary"
                    @click="handlerAddgoods"
                  >
                    设 置
                  </el-button>
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="使用说明：" prop="remark">
              <el-input
                v-model="form.remark"
                type="textarea"
                placeholder="请输入内容"
                :rows="4"
                maxlength="300"
                show-word-limit
                style="width: 620px"
              />
            </el-form-item>
          </el-form>
        </el-row>
        <link-client ref="linkClient" @setids="setids"></link-client>
        <add-goods ref="goods" @handlergitids="handlergitids"></add-goods>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handlersave">保 存</el-button>
        <el-button type="primary" @click="showcoupon = !showcoupon">
          取 消
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import AddGoods from './AddGoods.vue'
  import timestamp from '@/utils/timestamp'
  import { postAction } from '@/api/Employee'
  // import SetComponent from '@/views/project/sale/control/setPrice/components/createEdit/setComponent'
  import LinkClient from './customer'
  export default {
    components: {
      // SetComponent,
      LinkClient,
      AddGoods,
    },
    data() {
      // var validateids = (rule, value, callback) => {
      //   if (this.form.limit_cust_ids == '') {
      //     callback(new Error('请设置指定客户'))
      //   } else {
      //     callback()
      //   }
      // }
      // var validateenough = (rule, value, callback) => {
      //   if (value == '') {
      //     callback(new Error('请输入满减金额'))
      //   } else {
      //     callback()
      //   }
      // }
      var validatetype = (rule, value, callback) => {
        if (value == '1') {
          if (this.form.Time.length <= 0) {
            callback(new Error('请选择时间'))
          } else {
            callback()
          }
        } else {
          if (this.form.days != '') {
            callback()
          } else {
            callback(new Error('请输入天数'))
          }
        }
      }
      return {
        Usethreshold: 0, //使用门槛显示
        Receivable: 0, //可领客户显示
        Limitedcollar: 0, //每人限领显示
        range: 0, //使用范围
        showcoupon: false,
        user_data: 0,
        goods_data: 0,
        menkan: '0',
        form: {
          // id:'',//,添加时为0，修改时为对应id
          name: '', //名称
          amount: '', //金额
          enough: '', //满多少元可用，无门槛为0
          total: '', //发行总数
          Time: [],
          type: '1',
          starttime: '', //开始时间
          endtime: '', //结束时间
          days: '', //领取后几日可用，时间模式为0
          limit_cust_ids: '', //可领客户，逗号分割，全部为0
          getmax: '', //没人限领，不限次数为0
          limit_goods: '', //限制商品，逗号分割，全部为0
          remark: '', //说明
        },
        rules: {
          name: [
            { required: true, message: '请输入优惠券名称', trigger: 'blur' },
          ],
          amount: [
            { required: true, message: '请输入发行金额', trigger: 'blur' },
          ],
          enough: [{ required: true, message: '请输入门槛', trigger: 'blur' }],
          total: [
            {
              required: true,
              message: '请输入优惠券发行数量',
              trigger: 'blur',
            },
          ],
          type: [{ validator: validatetype, trigger: 'change' }],
          limit_cust_ids: [
            { required: true, message: '请设置限定人数', trigger: 'blur' },
          ],
          getmax: [
            { required: true, message: '请输入限领次数', trigger: 'blur' },
          ],
          limit_goods: [
            { required: true, message: '请设置限定商品', trigger: 'blur' },
          ],
          // remark: [{ required: true, message: '请输入说明', trigger: 'blur' }],
        },
        url: {
          save: '/shopAdmin/coupon/save',
          info: '/shopAdmin/coupon/info',
        },
      }
    },
    watch: {
      // Usethreshold: 0, //使用门槛显示
      //   Receivable: 0, //可领客户显示
      //   Limitedcollar: 0, //每人限领显示
      //   range: 0, //使用范围
      Usethreshold(val) {
        if (val == 0) {
          this.form.enough = 0
        }
      },
      Receivable(val) {
        if (val == 0) {
          this.form.limit_cust_ids = 0
        } else {
          // this.form.limit_cust_ids = ''
        }
      },
      Limitedcollar(val) {
        if (val == 0) {
          this.form.getmax = 0
        }
      },
      range(val) {
        if (val == 0) {
          this.form.limit_goods = 0
        }
      },
      'form.type'(val) {
        console.log(val)
        if (val == 1) {
          this.form.days = ''
        } else {
          this.form.Time = []
          this.form.starttime = ''
          this.form.endtime = ''
        }
      },
      showcoupon(val) {
        if (val == false) {
          this.user_data = 0
          this.title = '发行新优惠券'
          this.$refs['form'].resetFields()
          this.goods_data = 0
          this.form = {
            // id:'',//,添加时为0，修改时为对应id
            name: '', //名称
            amount: '', //金额
            enough: '', //满多少元可用，无门槛为0
            total: '', //发行总数
            Time: [],
            type: '1',
            starttime: '', //开始时间
            endtime: '', //结束时间
            days: '', //领取后几日可用，时间模式为0
            limit_cust_ids: '', //可领客户，逗号分割，全部为0
            getmax: '', //没人限领，不限次数为0
            limit_goods: '', //限制商品，逗号分割，全部为0
            remark: '', //说明
          }
        }
      },
    },
    methods: {
      setids(val) {
        console.log(val, '回传参数')
        this.user_data = val.length
        this.form.limit_cust_ids = val
      },
      handlerData(row) {
        postAction(this.url.info, { id: row.id })
          .then((res) => {
            console.log(res)
            this.$set(this.form, 'id', res.data.id)
            this.form.name = res.data.name //名称
            this.form.amount = res.data.amount //金额
            if (res.data.enough == 0) {
              this.form.enough = res.data.enough
              this.Usethreshold = 0
            } else {
              this.form.enough = res.data.enough //满多少元可用，无门槛为0
              this.Usethreshold = 1
            }
            this.form.total = res.data.total //发行总数
            this.form.Time = []
            this.form.starttime = res.data.starttime //开始时间
            this.form.endtime = res.data.endtime //结束时间
            this.form.days = res.data.days //领取后几日可用，时间模式为0
            if (res.data.limit_cust_ids == 0) {
              console.log(res.data.limit_cust_ids, '全部用户')
              this.form.limit_cust_ids = res.data.limit_cust_ids //可领客户，逗号分割，全部为0
              this.Receivable = 0
            } else {
              console.log(res.data.limit_cust_ids, '限定用户')
              this.form.limit_cust_ids = res.data.limit_cust_ids //可领客户，逗号分割，全部为0
              this.user_data = res.data.limit_cust_ids.length
              this.Receivable = 1
            }
            if (res.data.getmax == 0) {
              this.form.getmax = res.data.getmax //没人限领，不限次数为0
              this.Limitedcollar = 0
            } else {
              this.form.getmax = res.data.getmax //没人限领，不限次数为0
              this.Limitedcollar = 1
            }
            if (res.data.limit_goods == 0) {
              this.form.limit_goods = res.data.limit_goods //限制商品，逗号分割，全部为0
              this.range = 0
            } else {
              this.form.limit_goods = res.data.limit_goods //限制商品，逗号分割，全部为0
              this.range = 1
            }

            this.form.remark = res.data.remark
            if (res.data.timelimit == 2) {
              var T = new Array()
              // T.push(timestamp.timeFormat(res.data.starttime))
              // T.push(timestamp.timeFormat(res.data.endtime))
              this.form.days = ''
              T.push(res.data.starttime)
              T.push(res.data.endtime)
              this.form.Time = T
              console.log(this.form.Time, T, res.data.starttime)
              this.form.type = '1'
            } else {
              this.form.type = '0'
            }
            if (res.data.limit_cust_ids != '0') {
              try {
                this.user_data = res.data.limit_cust_ids.split(',').length
              } catch (error) {}
            }
            if (res.data.limit_goods != '0') {
              try {
                this.goods_data = res.data.limit_goods.split(',').length
              } catch (error) {}
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlergitids(val) {
        this.form.limit_goods = val
        this.goods_data = val.split(',').length
      },
      handlerAddgoods() {
        this.$refs.goods.showgoods = true
        if (this.form.limit_goods.length > 0) {
          this.$refs.goods.handlerinfolist(this.form.limit_goods)
        }
      },
      handlersave() {
        if (this.Usethreshold == 0) {
          this.form.enough = 0
        }
        if (this.Receivable == 0) {
          this.form.limit_cust_ids = 0
        } else {
          this.form.limit_cust_ids = this.form.limit_cust_ids.join(',')
        }
        if (this.Limitedcollar == 0) {
          this.form.getmax = 0
        }
        if (this.range == 0) {
          this.form.limit_goods = 0
        }
        if (this.form.type == 1) {
          this.form.days = ''
        } else {
          this.form.Time = []
          this.form.starttime = ''
          this.form.endtime = ''
        }
        console.log(this.form)

        this.$refs['form'].validate((valid) => {
          if (valid) {
            postAction(this.url.save, this.form)
              .then((res) => {
                console.log(res)
                console.log(res)
                this.$message({
                  message: res.msg,
                  type: 'success',
                })
                this.showcoupon = false
                this.$emit('getlist')
              })
              .catch((err) => {
                console.log(err)
              })
          } else {
            this.$message({
              message: '请将信息完善后提交',
              type: 'info',
            })
          }
        })
      },
      handlerpicker(res) {
        console.log(res)
        this.form.starttime = res[0]
        this.form.endtime = res[1]
        // this.form.starttime = timestamp.timestamp(this.form.starttime)
        // this.form.endtime = timestamp.timestamp(this.form.endtime)
        console.log(this.form.endtime, this.form.starttime)
      },
      handleSet() {
        if (this.Receivable != '0') {
          this.$refs.linkClient.showDialog = true
          // this.$refs.linkClient.id=this.form.limit_cust_ids
          if (this.form.limit_cust_ids != '') {
            this.$refs.linkClient.handlrselect(this.form.limit_cust_ids)
          }
        } else {
          this.$message({
            message: '请先选择限定客户',
            type: 'warning',
          })
        }
      },
      setUserId(val) {
        console.log(val, val.split(','))
        this.form.limit_cust_ids = val
        this.user_data = val.split(',').length
        // this.data.limit_count = this.data.limit_users.split(',').length
      },
    },
  }
</script>

<style></style>
