var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            visible: _vm.showgoods,
            title: "添加限定商品",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showgoods = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-table",
                { attrs: { stripe: "", data: _vm.tableData, height: "300px" } },
                [
                  _vm._l(_vm.colemd, function (list, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        label: list.label,
                        align: list.align,
                        prop: list.prop,
                        width: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          list.label == "商品名称"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var $index = ref.$index
                                  var row = ref.row
                                  return [
                                    _c("goods-search", {
                                      attrs: { "f-key": row.goods_name },
                                      on: {
                                        "add-rows": _vm.addRows,
                                        "select-goods-all": function ($event) {
                                          return _vm.selectGoods(
                                            $event,
                                            row,
                                            $index
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                },
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "center",
                      fixed: "right",
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerAdd(row, $index)
                                  },
                                },
                              },
                              [_vm._v(" 添加 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerDelet(row, $index)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showgoods = !_vm.showgoods
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }